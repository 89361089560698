import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { OrderForm } from '../forms'
import Testimonials from '../Testimonials'
import redditimg from '../../assets/img/redditimg.png'
import { Link } from 'gatsby'
import rewriteResults from '../../assets/img/startup-marketing-disrupt.gif'
import rewriteOriginal from '../../assets/img/startup-marketing-website.gif'
import rewritePerspective from '../../assets/img/startup-marketing-rewrite.gif'
import rewriteSprite from '../../assets/img/bold-rewrite-sprite.png'
import startupCommunity from '../../assets/img/startup-community.png'


const HomePageTemplate = ({ title, subtitle, meta_title, meta_description, testimonials }) => {
  return <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>
    <section className='hero is-medium'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-three-fifths is-offset-one-fifth'>
              <div className='section'>
                <h1 className='title is-size-1 has-text-weight-bold'>
                  {title}
                </h1>
                <div className='content is-size-5'>
                  <p>If your messaging is mindful of everyone, it’s probably sh*t.</p>
                  <p>Get expert advice, uniquely tailored suggestions, and copy and paste improvements for your URL in the next 72 hours 🚀</p>

                  <div className='columns mt-4'>
                    <Link
                      className='button is-primary is-medium column is-narrow'
                      to='/order'>
                        Book Yours Right Now
                    </Link>
                    <p className='column'>
                      <span className='is-size-7 is-italic has-text-grey'>Just £189 + a 100% money back guarantee</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='mini-quotes has-text-centered columns is-mobile is-multiline'>
            <p className='column stars is-half-mobile'>
              <span className='has-text-primary'>&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;</span>
              <br />
              <em className='has-text-grey is-size-7 is-italic'>&ldquo;&nbsp;&nbsp;totally unique perspective&nbsp;&nbsp;&rdquo;</em>
            </p>
            <p className='column stars is-half-mobile'>
              <span className='has-text-primary'>&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;</span>
              <br />
              <em className='has-text-grey is-size-7 is-italic'>&ldquo;&nbsp;&nbsp;insanely worth it&nbsp;&nbsp;&rdquo;</em>
            </p>
            <p className='column stars is-half-mobile'>
              <span className='has-text-primary'>&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;</span>
              <br />
              <em className='has-text-grey is-size-7 is-italic'>&ldquo;&nbsp;&nbsp;this text has changed us&nbsp;&nbsp;&rdquo;</em>
            </p>
            <p className='column stars is-half-mobile'>
              <span className='has-text-primary'>&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;</span>
              <br />
              <em className='has-text-grey is-size-7 is-italic'>&ldquo;&nbsp;&nbsp;unbeatable quality/price ratio&nbsp;&nbsp;&rdquo;</em>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section>


      <div className='container box has-text-centered px-6'>
        
        <aside className='columns py-5 is-variable is-8'>

          <div className='column'>
            <h3 className='has-text-weight-bold is-size-5 mb-3'>🙌 Your core audience will love this</h3>
            <p>If you want your marketing to be really, really great, it has to be really, really focused. This will help you celebrate your difference and lean into your audience.</p>
          </div>

          <div className='column'>
            <h3 className='has-text-weight-bold is-size-5 mb-3'>✂️ The choppy copy service</h3>
            <p>Persuasive writing isn't simple. It's about designing words on your page, and the difference it will make to your marketing is huge.</p>
          </div>

        </aside>

      </div>

    </section>

    <Testimonials testimonials={testimonials} />


    <article className='container section is-medium'>
      <div className='column is-10 is-offset-1'>
        <div className='columns is-variable is-8 is-vcentered'>

          <div className='column has-text-centered'>
            <h2 className='is-size-2 has-text-weight-bold mb-6'>It's how you'll gain more traction with marketing</h2>

            <img src={redditimg} alt='Bold Rewrites capture attention' />

            <span className='mb-4 mt-6 tag is-primary is-rounded'>Designed + refined with help from:</span>

            <img src={startupCommunity} alt='Helped by Reddit, ProductHunt and IndieHackers' />

          </div>

          <div className='column content'>

            <p>I've built + refined marketing for tons of startups.</p>

            <p>Most know why their product is game changing, but almost all fail to communicate that difference with their marketing.</p>

            <p>That's because when we work hard and look for long enough, we can uncover more use cases; cater to new investors; target more demographics…</p>

            <p>You're being inclusive. That's lovely.</p>

            <p>But I'm here to tell you – that's dumb.</p>

            <p>A website rewrite is the first step on your journey to stand out with your marketing and breakthrough with your audience. We don't work well for safe, standard offerings, which is why we focus on disruptive startups.</p>

            <p>This is disruptive step #1. Leaning into your difference, being authentic and attracting an audience that actually cares, because they feel the same.</p>

            <p>Being disruptive should be polarising.</p>

            <p>To stand out to the right people; you'll need to be confident, project with vigour, hold strong opinions… and that's not going to rub well with everyone.</p>

            <p>But I'm here to tell you – not only is that okay, it's how you'll gain traction.</p>

          </div>

        </div>
      </div>
    </article>


    <section className='section container has-text-centered'>

      <div className='content column is-three-fifths is-offset-one-fifth mb-6'>
        <h2 className='title is-size-2 has-text-weight-bold'>
          Traction without throwing 💰 into ads
        </h2>
        <p>The only way to get more people to agree with you, is to get more people to disagree with you. We'll rewrite your landing page, and show you how it'll win more customers.</p>
      </div>

      <div className='column is-10 is-offset-1'>

        <div className='columns is-variable is-8 is-vcentered'>
          <div className='column one-half'>
            <img src={rewriteResults} alt='Startup Rewrite Results' width='80%' />
          </div>
          <div className='column one-half'>
            <span className='mb-4 tag is-primary is-rounded'>Designed for results</span>
            <p className='is-size-4'>Detailed feedback on the key messaging that needs to change, and a copy & paste alternative of what will work better.</p>
          </div>
        </div>

        <div className='columns is-variable is-8 is-vcentered reverse-row-order'>
          <div className='column one-half'>
            <img src={rewriteOriginal} alt='Startup Rewrite Original' width='80%' />
          </div>
          <div className='column one-half'>
            <span className='mb-4 tag is-primary is-rounded'>100% Original</span>
            <p className='is-size-4'>Uniquely tailored to your website, industry and product – we'll pump your marketing with personality and tell you why that's important.</p>
          </div>
        </div>

        <div className='columns is-variable is-8 is-vcentered'>
          <div className='column one-half'>
            <img src={rewritePerspective} alt='Startup Rewrite Unique Perspective' width='80%' />
          </div>
          <div className='column one-half'>
            <span className='mb-4 tag is-primary is-rounded'>A fresh perspective</span>
            <p className='is-size-4'>Get a fresh perspective on your business and sales messaging, and a better understanding of how to get things rocketing forward.</p>
          </div>
        </div>

      </div>

    </section>

    <section className='section container'>

      <div className='content box has-text-centered px-6 py-6'>
        
        <div>
          <h2 className='title is-size-2 has-text-weight-bold'>
            Startups have used these rewrites:
          </h2>
        </div>

        <div className='columns is-variable is-8'>

          <div className='column'>
            <div className='sprite rewriteSprite'>
              <img className='one' src={rewriteSprite} alt='Test landing pages' />
            </div>
            <p>to A/B test landing pages</p>
          </div>

          <div className='column'>
            <div className='sprite rewriteSprite'>
              <img className='two' src={rewriteSprite} alt='Test landing pages' />
            </div>
            <p>as a copy and paste refresh</p>
          </div>

          <div className='column'>
            <div className='sprite rewriteSprite'>
              <img className='three' src={rewriteSprite} alt='Test landing pages' />
            </div>
            <p>to repurpose and use on social</p>
          </div>

          <div className='column'>
            <div className='sprite rewriteSprite'>
              <img className='four' src={rewriteSprite} alt='Test landing pages' />
            </div>
            <p>to stand out in the SERPs</p>
          </div>

          <div className='column'>
            <div className='sprite rewriteSprite'>
              <img className='five' src={rewriteSprite} alt='Test landing pages' />
            </div>
            <p>to inspire new blog posts</p>
          </div>

        </div>

      </div>

    </section>

    <section className='section container has-text-centered'>
      <h2 className='title is-size-2 has-text-weight-bold column is-half is-offset-one-quarter'>
         Stand out with your marketing and breakthrough with your audience
      </h2>

      <Link
        className='button is-primary is-medium'
        to='/order'>
                Book Yours Right Now
      </Link>
      <p><span className='is-size-7 is-italic has-text-grey'>Only £189 with a 100% money back guarantee</span></p>

      <div className='columns my-6'>

        <div className='column'>
          <h4 className='has-text-weight-bold is-size-4 mb-4'>Do not order this if:</h4>
          <p>🚫 You want safe, standard changes</p>
          <p>🚫 You need something written from scratch</p>
          <p>🚫 You expect every word to be rewritten</p>
          <p>🚫 You want to ask for adjustments / amends</p>
          <p>🚫 You need a spelling and grammar check</p>
        </div>

        <div className='column'>
          <h4 className='has-text-weight-bold is-size-4 mb-4'>Order this if:</h4>
          <p>✅ You want to stand out with bold messaging</p>
          <p>✅ You need an experts opinion on your website</p>
          <p>✅ You want a fresh perspective on your tone</p>
          <p>✅ You'd like new creative ideas on how to pitch your startup</p>
        </div>

      </div>

    </section>

  </div>
}

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  testimonials: PropTypes.array,
}

export default HomePageTemplate
